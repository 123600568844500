import { GetLocalizedPaths } from '../types/GetLocalizedPaths';
import { lowerCaseFirstLetter, removeLeadingSlash } from './helpers';

export const GetInternalLinks = (
  item: any,
  paths: any,
  getLocalizedPath: GetLocalizedPaths,
  parentLink?: string,
) => {
  let LinkUrl;
  if (paths) {
    if (item?.internalLink && item?.internalLink?.__typename === 'GenericPage') {
      if (item.internalLink.type === 'Generic') {
        LinkUrl = getLocalizedPath(paths?.genericPage, item?.internalLink?.slug);
      } else if (item.internalLink.type) {
        // this should be able to handle About | Product Promotios | Services | Support | inspiration pages
        LinkUrl = getLocalizedPath(
          paths?.[lowerCaseFirstLetter(item.internalLink.type)],
          `${item.internalLink.subPath ? removeLeadingSlash(item.internalLink.subPath) : ''}${
            item.internalLink.slug
          }`,
          true,
        );
      }
    } else if (item?.internalLink && item?.internalLink?.__typename === 'ProductSubcategoryPage') {
      const categorySlug = item?.internalLink?.productCategoryPage?.slug;
      LinkUrl = getLocalizedPath(
        paths?.productsSubCategory,
        `${parentLink ? `${parentLink}/` : ''}${categorySlug ? `${categorySlug}/` : ''}${
          item?.internalLink.slug
        }`,
        true,
      );
    } else if (item?.internalLink && item?.internalLink.__typename === 'ProductCategoryPage') {
      LinkUrl = getLocalizedPath(paths?.productsCategory, item?.internalLink.slug);
    } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeLandingPage') {
      LinkUrl = getLocalizedPath(paths?.recipes, item.internalLink.slug);
    } else if (item?.internalLink && item?.internalLink.__typename === 'ProductLandingPage') {
      LinkUrl = getLocalizedPath(paths?.products, item.internalLink.slug);
    } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeCategory') {
      LinkUrl = getLocalizedPath(paths?.recipesCategory, item?.internalLink.slug);
    } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeSubcategory') {
      LinkUrl = getLocalizedPath(
        paths?.recipesSubCategory,
        `${parentLink ? `${parentLink}/` : ''}${item?.internalLink.slug}`,
        true,
      );
    }
  }

  return LinkUrl;
};
