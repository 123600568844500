import React from 'react';
import { Text } from '../Typography';
import { Metaballs } from '../MetaballsGooey';

import {
  Container,
  ImageSection,
  ContentSection,
  SubHeading,
  Heading,
  ButtonWrapper,
  Image,
  MetaballContainer,
  MainMetaball,
} from './styles';

import { ImageTextType } from './types';
import { getPictureSources } from '../../modules/getPictureSources';
import GetSafari from '../../modules/getSafari';
import Rewrite from '../../types/Rewrite';
import { GetInternalLinks } from '../../modules/getInternalLink';
import { GetLocalizedPaths } from '../../types/GetLocalizedPaths';
import { CorpBackgroundColor } from '../../types/CorpBackgroundColors';
import { CorporateButton } from '../CorporateButton';

export const CorpImageTextCard = ({
  cardData,
  metaBall,
  rewrites,
  getLocalizedPath,
  color,
}: {
  cardData?: ImageTextType;
  metaBall?: boolean;
  rewrites?: Record<string, Rewrite>;
  getLocalizedPath: GetLocalizedPaths;
  color: CorpBackgroundColor;
}) => {
  const isSafari = GetSafari();
  const svgMaskedImage = () => {
    if (isSafari) {
      return cardData?.image?.asset?.[0]?.desktopFallback;
    } else {
      return cardData?.image?.asset?.[0]?.desktop;
    }
  };
  return (
    <Container $color={color}>
      {cardData?.image?.asset?.[0] && !metaBall && (
        <ImageSection>
          <svg
            viewBox="0 0 628 265"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <defs>
              <clipPath id="mask11">
                <path
                  d="M16 0C7.16344 0 0 7.16343 0 16V265C0 265 144.353 243.757 315.308 243.757C486.264 243.757 628 265 628 265V16C628 7.16344 620.837 0 612 0H16Z"
                  fill="#FA85BD"
                />
              </clipPath>
            </defs>
            <image
              xlinkHref={svgMaskedImage()}
              width="100%"
              height="100%"
              clipPath="url(#mask11)"
            />
          </svg>
        </ImageSection>
      )}
      {cardData?.image?.asset?.[0] && metaBall && (
        <MetaballContainer>
          <MainMetaball>
            <Metaballs duration={10} color="#DFF0D1" />
          </MainMetaball>
          <Image sources={getPictureSources(cardData?.image?.asset?.[0])} alt="" />
        </MetaballContainer>
      )}
      <ContentSection>
        <div>
          {cardData?.subHeading && (
            <SubHeading tag="div" type="lead" className={metaBall ? 'mt-0' : 'mt-30'}>
              {cardData?.subHeading}
            </SubHeading>
          )}
          {cardData?.title && (
            <Heading tag="div" type="xs">
              {cardData?.title}
            </Heading>
          )}
          {cardData?.description && (
            <Text tag="p" type="md">
              {cardData?.description}
            </Text>
          )}
        </div>
        {cardData?.buttonLink?.displayName && (
          <ButtonWrapper tag="div" type="lead">
            <a
              href={
                GetInternalLinks(
                  cardData?.buttonLink,
                  rewrites,
                  getLocalizedPath,
                  cardData?.parentPage,
                ) || cardData?.buttonLink.url
              }
              target={cardData?.buttonLink.openInNewTab ? '_blank' : '_self'}
              rel="noreferrer"
            >
              <span className="sr-only">{cardData.buttonLink.displayName}</span>
              <CorporateButton label={cardData?.buttonLink?.displayName} type="primary" />
            </a>
          </ButtonWrapper>
        )}
      </ContentSection>
    </Container>
  );
};
CorpImageTextCard.defaultProps = {
  metaBall: false,
};
