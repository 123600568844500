import React, { useState, useEffect, useMemo } from 'react';
import Slider, { LazyLoadTypes } from 'react-slick';
import { Grid, GridRow, GridItem } from '../Grid';
import { CorpImageTextCard } from '../CorpImageTextCard';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  Container,
  GridWrapper,
  Image,
  StaticBlob,
  MobileSlider,
  MobileCardWrapper,
} from './styles';

import { CorpGridColumnData, CardData } from './types';
import Rewrite from '../../types/Rewrite';
import { GetLocalizedPaths } from '../../types/GetLocalizedPaths';

export const CorpGridColumnComponent = ({
  data,
  id = 'gridColumn',
  getLocalizedPath,
  rewrites,
}: {
  data?: CorpGridColumnData;
  id?: string;
  getLocalizedPath: GetLocalizedPaths;
  rewrites?: Record<string, Rewrite>;
}) => {
  const settings = {
    dots: true,
    lazyLoad: 'ondemand' as LazyLoadTypes,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const objectTemplate = (gridData: CorpGridColumnData) => (
    <>
      {gridData?.assetComponent && (
        <>
          <Image
            data-test="grid-column-image"
            src={gridData?.assetComponent.asset?.[0]?.url}
            alt=""
          />
          {gridData?.gridMetaball && (
            <StaticBlob data-test="grid-column-static-blob">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 337 290"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  opacity="0.5"
                  d="M294.341 240.039C294.341 267.632 272.011 290 244.464 290C231.867 290 220.36 285.322 211.581 277.606C199.174 266.7 179.93 257.085 163.985 261.365C94.1974 280.096 22.7227 239.576 4.34159 170.862C-2.4629 145.425 -1.03754 119.69 6.99734 96.4129C12.7573 79.7263 6.22979 58.7506 3.70301 41.277C3.45678 39.5743 3.32933 37.8331 3.32933 36.062C3.32934 16.1455 19.4478 -6.31048e-06 39.331 0C44.6579 5.32703e-07 49.7147 1.15889 54.2637 3.23871C67.4407 9.26334 83.4298 16.2843 97.421 12.5291C145.025 -0.247781 193.414 14.5445 225.201 47.0492C233.744 55.7845 245.867 60.3294 258.066 60.7923C263.842 61.0115 269.691 61.8428 275.53 63.3337C318.924 74.4125 345.369 117.715 334.596 160.053C330.577 175.847 321.946 189.275 310.451 199.33C299.587 208.833 294.341 225.591 294.341 240.039Z"
                  fill="#010E54"
                />
              </svg>
            </StaticBlob>
          )}
        </>
      )}
    </>
  );
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  const ctaExists = useMemo(() => {
    if (data?.cardData) {
      if (data.cardData.filter((card: any) => card?.buttonLink != null).length > 0) {
        return true;
      }
    }
    return false;
  }, [data?.cardData?.length]);
  const numberOfCards = data?.cardData?.length || 0;

  return (
    <Container data-testid="GridColumnComponent" data-test="grid-column-container">
      {data && (
        <GridWrapper>
          {screenWidth <= 475 ? (
            <MobileSlider data-test="grid-column-mobile-slider" $ctaExists={ctaExists}>
              {objectTemplate(data)}
              <Slider data-test="grid-column-slider" {...settings} className="mb-14">
                {data.cardData &&
                  data?.cardData?.map((card: CardData, index: number) => (
                    <MobileCardWrapper key={id + index}>
                      <CorpImageTextCard
                        data-test="grid-column-image-text-card-under-475"
                        cardData={card}
                        color={data?.color}
                        metaBall={card.metaBall}
                        getLocalizedPath={getLocalizedPath}
                        rewrites={rewrites}
                      />
                    </MobileCardWrapper>
                  ))}
              </Slider>
            </MobileSlider>
          ) : (
            <Grid>
              <GridRow columns={16}>
                {objectTemplate(data)}
                {data.cardData &&
                  data?.cardData?.map((card: CardData, index: number) => (
                    <GridItem
                      colSpan={8}
                      key={id + index + 0}
                      colStart={numberOfCards % 2 !== 0 && index === numberOfCards - 1 ? 5 : 0}
                    >
                      <CorpImageTextCard
                        data-test="grid-column-image-text-card-over-475"
                        cardData={card}
                        color={data?.color}
                        metaBall={card.metaBall}
                        getLocalizedPath={getLocalizedPath}
                        rewrites={rewrites}
                      />
                    </GridItem>
                  ))}
              </GridRow>
            </Grid>
          )}
        </GridWrapper>
      )}
    </Container>
  );
};
