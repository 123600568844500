import React from 'react';

// Gooey
// Taken from gooey-react lib, with modified blur values

export const Goo = ({
  children,
  className,
  composite = false,
  intensity = 'medium',
  intensityValue = null,
  id = 'gooey-react',
  style,
}: {
  children: React.ReactNode;
  className?: string;
  composite?: boolean;
  intensity?: 'weak' | 'medium' | 'strong';
  intensityValue?: number | null;
  id?: string;
  style?: React.CSSProperties;
}) => {
  let blur = intensity === 'weak' ? 8 : intensity === 'strong' ? 32 : 12;

  // IntensityValue prop overrides the wishy-washy "intensity" string
  if (intensityValue) {
    blur = intensityValue;
  }

  const alpha = blur * 6;
  const shift = alpha / -2;
  const r = '1 0 0 0 0';
  const g = '0 1 0 0 0';
  const b = '0 0 1 0 0';
  const a = `0 0 0 ${alpha} ${shift}`;

  return (
    <>
      <svg
        data-testid="svg"
        style={{
          pointerEvents: 'none',
          position: 'absolute',
        }}
      >
        <defs>
          <filter colorInterpolationFilters="sRGB" data-testid="filter" id={id}>
            <feGaussianBlur data-testid="blur" stdDeviation={blur} />
            <feColorMatrix values={`${r} ${g} ${b} ${a}`} />
            {composite && <feComposite data-testid="composite" in="SourceGraphic" />}
          </filter>
        </defs>
      </svg>
      <div
        className={className}
        data-testid="element"
        style={{
          ...style,
          filter: `url(#${id})`,
        }}
      >
        {children}
      </div>
    </>
  );
};

Goo.defaultProps = {
  className: '',
  composite: false,
  intensity: 'strong',
  intensityValue: null,
  id: 'gooey-react',
  style: {},
};
