import tw from 'tailwind-styled-components';
import { Title, Text } from '../Typography';
import { Picture } from '../../modules/responsivePicture';
import styled from 'styled-components';
import { CorpBackgroundColor } from '../../types/CorpBackgroundColors';

export const Container = tw.section<{
  $color?: CorpBackgroundColor;
}>`
  w-full
  h-full
  p-0
  flex
  flex-col
  relative
  rounded-t-2xl
  rounded-b-2xl
  overflow-hidden
  ${({ $color }) => ($color === 'peach' ? 'bg-corporate-peach' : 'bg-corporate-lightBlue')}

`;
export const ImageSection = styled.div`
  image {
    width: 100%;
    height: auto;
  }
`;
export const ContentSection = tw.div`
  text-center
  relative
  p-4
  flex
  flex-col
  justify-between
  h-full
`;
export const ImageContainer = tw.img`
  w-full
`;
export const SubHeading = tw(Text)`
  mb-5
  text-darkgray
`;
export const Heading = tw(Title)`
  mb-5
`;
export const ButtonWrapper = tw(Text)`
  mx-auto
  h-36
  flex
  justify-center
  items-center
`;
export const Image = tw(Picture)`
  h-72
  mx-auto
  relative
`;
Image.displayName = 'Image';

export const MetaballContainer = tw.div`
  max-h-96
  overflow-hidden
`;
export const MainMetaball = tw.div`
  absolute
  w-full
  h-3/4
  pt-10
`;
export const staticBlob = tw.svg``;
