import tw from 'tailwind-styled-components';
import styled from 'styled-components';

export const Container = tw.section`
  max-w-none
  overflow-hidden
  p-0
`;
export const GridWrapper = tw.div`
  relative
  overflow-hidden
`;
export const Image = tw.img`
  absolute
  md:top-0
  sm:-top-10
  right-0
  z-10
  md:w-1/5
  sm:w-3/12
`;
export const StaticBlob = tw.div`
  absolute
  sm:-top-12
  md:top-0
  sm:-right-5
  md:-right-20
  sm:w-150
  md:w-1/5
`;
export const MobileCardWrapper = styled.div`
  padding-left: 6%;
  padding-right: 6%;
`;
export const MobileSlider = styled.div<{ $ctaExists: boolean }>`
  position: relative;
  .slick-initialized .slick-slide {
    padding-right: 0;
  }
  .slick-dots {
    left: 0;
    bottom: 0px;
    li {
      width: 10px;
      button {
        width: 10px;
        &:before {
          width: 10px;
          font-size: 8px;
          color: #5a5e5a;
          opacity: 0.5;
        }
      }
      &:first-child,
      &:last-child {
        button:before {
          font-size: 6px;
        }
      }
      &.slick-active button:before {
        font-size: 10px;
        opacity: 1;
      }
    }
  }
  ${({ $ctaExists }) =>
    !$ctaExists &&
    `.slick-slider {
      padding-bottom: 55px;}`}
`;
